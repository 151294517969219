define("ember-aria-tabs/components/aria-tab-list", ["exports", "ember-aria-tabs/templates/components/aria-tab-list"], function (_exports, _ariaTabList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * List of all tabs component rendered as `<ul />` .
   *
   * If you specify additional props on the `<AriaTabList />` component they will be forwarded to the rendered `<ul />`.
   *
   * Default CSS class: `ember-tabs__tab-list`
   *
   * @class AriaTabList
   * @public
   */
  var _default = Ember.Component.extend({
    layout: _ariaTabList.default,
    tagName: 'ul',
    classNames: ['ember-tabs__tab-list'],
    attributeBindings: ['role'],
    role: Ember.computed({
      get() {
        return 'tablist';
      }

    }).readOnly()
  });

  _exports.default = _default;
});