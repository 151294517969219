define("ember-svg-jar/inlined/noun_Arrow_3134228", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Forward</title><path d=\"M50 0A50 50 0 000 50a50 50 0 0050 50 50 50 0 0050-50A50 50 0 0050 0zM26.67 75V55.56L60 50l-33.33-5.56V25l46.66 25z\"/>",
    "attrs": {
      "height": "300",
      "width": "300",
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});