define("emberfire/torii-adapters/firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FirebaseToriiAdapter extends Ember.Object.extend({
    firebaseApp: Ember.inject.service('firebase-app')
  }) {
    open(user) {
      return Ember.RSVP.resolve(user);
    }

    restore() {
      return new Ember.RSVP.Promise(resolve => {
        Ember.get(this, 'firebaseApp').auth().then(auth => {
          const unsubscribe = auth.onIdTokenChanged(currentUser => Ember.run(() => {
            unsubscribe();

            if (currentUser) {
              resolve({
                currentUser
              });
            } else {
              Ember.RSVP.reject();
            }
          }));
        });
      });
    }

    close() {
      return Ember.get(this, 'firebaseApp').auth().then(auth => auth.signOut());
    }

  }

  _exports.default = FirebaseToriiAdapter;
});