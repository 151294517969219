define("ember-power-select/utils/computed-options-matcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = computedOptionsMatcher;

  function computedOptionsMatcher(matcherField, defaultMatcher) {
    return Ember.computed('searchField', matcherField, function () {
      let {
        [matcherField]: matcher,
        searchField
      } = this.getProperties(matcherField, 'searchField');

      if (searchField && matcher === defaultMatcher) {
        return (option, text) => matcher(Ember.get(option, searchField), text);
      } else {
        return (option, text) => {
          (false && !(matcher !== defaultMatcher || typeof option === 'string') && Ember.assert('<PowerSelect> If you want the default filtering to work on options that are not plain strings, you need to provide `@searchField`', matcher !== defaultMatcher || typeof option === 'string'));
          return matcher(option, text);
        };
      }
    });
  }
});