define("ember-svg-jar/inlined/arrow_r", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M53.041 21c-15.739 0-28.5 12.76-28.5 28.5S37.302 78 53.041 78s28.5-12.76 28.5-28.5S68.78 21 53.041 21zm-3.293 38.23a1.494 1.494 0 01-1.059.437 1.501 1.501 0 01-1.059-2.563l7.656-7.624-7.675-7.937a1.5 1.5 0 112.156-2.085l9.731 10.063-9.75 9.709z\"/>",
    "attrs": {
      "height": "300",
      "width": "300",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});