define("ember-paper/components/paper-autocomplete/ebd-content/component", ["exports", "ember-paper/components/paper-autocomplete/ebd-content/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let PaperAutocompleteEbdContent = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_temp = class PaperAutocompleteEbdContent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "customStyles", {
        'overflow-y': 'auto',
        'overflow-x': 'hidden',
        'height': 'auto!important'
      });
    }

    shouldReposition(mutations) {
      let shouldReposition = false;
      shouldReposition = Array.prototype.slice.call(mutations[0].addedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }

        return false;
      });
      shouldReposition = shouldReposition || Array.prototype.slice.call(mutations[0].removedNodes).some(node => {
        if (node.classList) {
          return !node.classList.contains('md-ripple') && node.nodeName !== '#comment' && !(node.nodeName === '#text' && node.nodeValue === '');
        }

        return false;
      });
      return shouldReposition;
    }

  }, _temp)) || _class) || _class);
  var _default = PaperAutocompleteEbdContent;
  _exports.default = _default;
});