define("emberfire/torii-providers/firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FirebaseToriiProvider extends Ember.Object.extend({}) {
    open() {
      return Ember.RSVP.reject(new Error('Please authenticate via the Firebase SDK directly.'));
    }

  }

  _exports.default = FirebaseToriiProvider;
});