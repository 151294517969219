define("ember-aria-tabs/templates/components/aria-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MIXlHnli",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"selected\"],[[32,0,[\"selected\"]]]]]]]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tab.hbs"
    }
  });

  _exports.default = _default;
});