define("ember-aria-tabs/templates/components/aria-tabs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dODC0hOn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,2],null,[[\"tabList\",\"tabPanel\"],[[30,[36,0],[\"aria-tab-list\"],[[\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyDown\",\"panelNodes\",\"parentGuid\",\"selectedIndex\",\"selectedTabClassName\",\"tabNodes\"],[[32,0,[\"disabledTabClassName\"]],[32,0,[\"focus\"]],[30,[36,1],[[32,0],\"handleClick\"],null],[30,[36,1],[[32,0],\"handleKeyDown\"],null],[32,0,[\"panelNodes\"]],[32,0,[\"elementId\"]],[32,0,[\"selectedIndex\"]],[32,0,[\"selectedTabClassName\"]],[32,0,[\"tabNodes\"]]]]],[30,[36,0],[\"aria-tab-panel\"],[[\"forceRender\",\"panelNodes\",\"parentGuid\",\"selectedTabPanelClassName\",\"selectedIndex\",\"tabNodes\"],[[32,0,[\"forceRenderTabPanel\"]],[32,0,[\"panelNodes\"]],[32,0,[\"elementId\"]],[32,0,[\"selectedTabPanelClassName\"]],[32,0,[\"selectedIndex\"]],[32,0,[\"tabNodes\"]]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tabs.hbs"
    }
  });

  _exports.default = _default;
});