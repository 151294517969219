define("ember-cli-notifications/services/notifications", ["exports", "ember-get-config"], function (_exports, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const notificationAssign = Ember.assign || Ember.merge;
  const globals = _emberGetConfig.default['ember-cli-notifications'] || {}; // Import app config object

  var _default = Ember.Service.extend({
    content: Ember.A(),

    // Method for adding a notification
    addNotification(options) {
      // If no message is set, throw an error
      if (!options.message) {
        throw new Error("No notification message set");
      }

      const notification = Ember.Object.create({
        message: options.message,
        type: options.type || 'info',
        autoClear: Ember.isEmpty(options.autoClear) ? Ember.getWithDefault(globals, 'autoClear', false) : options.autoClear,
        clearDuration: options.clearDuration || Ember.getWithDefault(globals, 'clearDuration', 3200),
        onClick: options.onClick,
        htmlContent: options.htmlContent || false,
        cssClasses: options.cssClasses
      });
      this.content.pushObject(notification);

      if (notification.autoClear) {
        notification.set('remaining', notification.get('clearDuration'));
        this.setupAutoClear(notification);
      }

      return notification;
    },

    // Helper methods for each type of notification
    error(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'error'
      }, options));
    },

    success(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'success'
      }, options));
    },

    info(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'info'
      }, options));
    },

    warning(message, options) {
      return this.addNotification(notificationAssign({
        message: message,
        type: 'warning'
      }, options));
    },

    removeNotification(notification) {
      if (!notification) {
        return;
      }

      notification.set('dismiss', true); // Delay removal from DOM for dismissal animation

      Ember.run.later(this, () => {
        this.content.removeObject(notification);
      }, 500);
    },

    setupAutoClear(notification) {
      notification.set('startTime', Date.now());
      const timer = Ember.run.later(this, () => {
        // Hasn't been closed manually
        if (this.content.indexOf(notification) >= 0) {
          this.removeNotification(notification);
        }
      }, notification.get('remaining'));
      notification.set('timer', timer);
    },

    pauseAutoClear(notification) {
      Ember.run.cancel(notification.get('timer'));
      const elapsed = Date.now() - notification.get('startTime');
      const remaining = notification.get('clearDuration') - elapsed;
      notification.set('remaining', remaining);
    },

    clearAll() {
      this.get('content').forEach(notification => {
        this.removeNotification(notification);
      });
      return this;
    },

    setDefaultAutoClear(autoClear) {
      Ember.set(globals, 'autoClear', autoClear);
    },

    setDefaultClearDuration(clearDuration) {
      Ember.set(globals, 'clearDuration', clearDuration);
    }

  });

  _exports.default = _default;
});