define("emberfire/services/firebase-app", ["exports", "firebase/auth", "firebase/firestore", "firebase/storage"], function (_exports, _auth, _firestore, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const getApp = service => {
    const firebase = Ember.get(service, 'firebase');
    const name = Ember.get(service, 'name');
    return firebase.app(name);
  };

  class FirebaseAppService extends Ember.Service.extend({
    name: undefined,
    firebase: Ember.inject.service('firebase')
  }) {
    constructor() {
      super(...arguments);

      this.delete = () => getApp(this).delete();

      this.auth = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/auth")).then(() => getApp(this).auth());

      this.analytics = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/analytics")).then(() => getApp(this).analytics());

      this.firestore = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/firestore")).then(() => getApp(this).firestore());

      this.messaging = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/messaging")).then(() => getApp(this).messaging());

      this.performance = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/performance")).then(() => getApp(this).performance());

      this.remoteConfig = () => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/remote-config")).then(() => getApp(this).remoteConfig());

      this.database = url => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/database")).then(() => getApp(this).database(url));

      this.functions = region => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/functions")).then(() => getApp(this).functions(region));

      this.storage = url => Ember.RSVP.resolve(emberAutoImportDynamic("firebase/storage")).then(() => getApp(this).storage(url));
    }

    init(...args) {
      // @ts-ignore because ember do pass arguments here
      super.init(...args);
      const app = getApp(this);
      Ember.set(this, 'options', app.options);
    }

  }

  _exports.default = FirebaseAppService;
});