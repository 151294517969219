define('ember-body-class/mixins/body-class', ['exports', 'ember-body-class/util/bodyClass'], function (exports, _bodyClass) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    actions: {
      loading(transition) {
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;

        (0, _bodyClass.addClass)(body, 'loading');

        transition.finally(function () {
          (0, _bodyClass.removeClass)(body, 'loading');
        });

        return true;
      },

      error: function () /* error, transition */{
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;

        (0, _bodyClass.addClass)(body, 'error');

        let router = this._router;

        if (router) {
          router.on('didTransition', function () {
            (0, _bodyClass.removeClass)(body, 'error');
          });
        }

        return true;
      }
    }
  });
});