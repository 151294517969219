define("ember-paper-link/templates/components/paper-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lprZsDr9",
    "block": "{\"symbols\":[\"&default\",\"@iconButton\"],\"statements\":[[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[18,1,null]],\"parameters\":[]},{\"statements\":[[1,[32,0,[\"linkTitle\"]]]],\"parameters\":[]}]]],[8,\"paper-ripple\",[],[[\"@fitRipple\",\"@center\",\"@dimBackground\"],[[32,2],[32,2],[30,[36,1],[[32,2]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"not\"]}",
    "meta": {
      "moduleName": "ember-paper-link/templates/components/paper-link.hbs"
    }
  });

  _exports.default = _default;
});