define("ember-google-maps/components/g-map/polyline", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/utils/options-and-events"], function (_exports, _mapComponent, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * A wrapper for the google.maps.Polyline class.
   *
   * @class Polyline
   * @namespace GMap
   * @module ember-google-maps/components/g-map/polyline
   * @extends GMap.MapComponent
   */
  var _default = _mapComponent.default.extend({
    _type: 'polyline',

    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), {}, {
        path: Ember.get(this, 'path'),
        map: Ember.get(this, 'map')
      });
    },

    _addComponent(options) {
      return Ember.RSVP.resolve(Ember.set(this, 'mapComponent', new google.maps.Polyline(options)));
    },

    _didAddComponent() {
      let watched = (0, _optionsAndEvents.watch)(this, {
        'path.[]': () => this._updateOrAddComponent()
      });
      watched.forEach(({
        name,
        remove
      }) => this._eventListeners.set(name, remove));
      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});