define("ember-svg-jar/inlined/kompass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5958 8793c-107-232-274-596-305-668-19-44-40-85-47-91-6-7-42-19-79-28-443-109-834-354-1119-701-196-239-331-513-412-834l-21-84-170-77c-226-102-248-112-335-152-41-19-109-50-150-69-102-45-133-63-138-77-2-7 69-47 180-101 286-139 550-266 584-281 28-12 33-21 49-89 88-382 300-753 584-1023 265-253 574-421 930-509l94-23 22-50c13-28 42-96 65-151s64-152 92-215c187-434 195-450 213-450 8 0 19 10 24 23 5 12 28 63 51 112 54 117 87 188 140 305 24 52 57 124 73 160 17 36 46 101 66 145 54 119 46 112 166 145 374 100 670 270 950 545 37 37 150 170 192 225 157 210 286 493 348 762l18 76 76 35c186 86 440 202 486 222 140 60 235 109 235 122 0 8-69 47-167 95-93 44-251 121-353 170s-206 98-231 109l-46 20-32 121c-99 374-271 676-537 942-272 272-604 458-980 550l-71 17-28 62c-15 34-33 78-40 97s-20 49-28 65c-8 17-53 120-100 230-143 336-173 400-191 403-12 3-27-20-58-85z\" fill=\"#37949a\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "1200",
      "height": "1200",
      "viewBox": "0 0 12000 12000"
    }
  };
  _exports.default = _default;
});