define("ember-google-maps/components/g-map/directions", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/directions", "ember-google-maps/utils/options-and-events", "ember-concurrency"], function (_exports, _mapComponent, _directions, _optionsAndEvents, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DirectionsAPI = DirectionsAPI;
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function DirectionsAPI(source) {
    let mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      get directions() {
        return source.directions;
      },

      get waypoints() {
        return source.waypoints;
      },

      actions: {
        route: () => source.route()
      }
    });
  }
  /**
   * A wrapper for the google.maps.directionsService API.
   *
   * @class Directions
   * @namespace GMap
   * @module ember-google-maps/components/g-map/directions
   * @extends GMap.MapComponent
   */


  var _default = _mapComponent.default.extend({
    googleMapsApi: Ember.inject.service(),
    layout: _directions.default,
    _type: 'directions',
    _pluralType: 'directions',
    directionsService: Ember.computed.reads('googleMapsApi.directionsService'),
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'onDirectionsChanged']),

    _createOptions(options) {
      return _objectSpread(_objectSpread({}, options), Ember.getProperties(this, ['origin', 'destination', 'travelMode', 'waypoints']));
    },

    init() {
      this._super(...arguments);

      this.waypoints = Ember.A();
      this.publicAPI = DirectionsAPI(this);
    },

    _addComponent(options) {
      return this.route(options);
    },

    _updateComponent(_, options) {
      return this.route(options);
    },

    _didAddComponent() {
      let watched = (0, _optionsAndEvents.watch)(this, {
        'waypoints.[]': () => this._updateOrAddComponent()
      });
      watched.forEach(({
        name,
        remove
      }) => this._eventListeners.set(name, remove));
      return this._super(...arguments);
    },

    /**
     * Fetch routing information from DirectionsService.
     *
     * @method route
     * @public
     */
    route(options) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        Ember.run.scheduleOnce('afterRender', this, this._performRouteTask, options, resolve, reject);
      });
    },

    _performRouteTask(options, resolve, reject) {
      Ember.get(this, 'routeTask').perform(options).then(result => resolve(result)).catch(e => {
        if (!(0, _emberConcurrency.didCancel)(e)) {
          reject(e);
        }
      });
    },

    routeTask: (0, _emberConcurrency.task)(function* (options) {
      let directions = yield Ember.get(this, 'fetchDirections').perform(options);
      Ember.setProperties(this, {
        directions,
        mapComponent: directions
      });
      Ember.run.schedule('afterRender', () => Ember.tryInvoke(this, 'onDirectionsChanged', [this.publicAPI]));
      return directions;
    }).restartable(),
    fetchDirections: (0, _emberConcurrency.task)(function* (options) {
      let directionsService = yield Ember.get(this, 'directionsService');
      let request = new Ember.RSVP.Promise((resolve, reject) => {
        directionsService.route(options, (response, status) => {
          if (status === 'OK') {
            resolve(response);
          } else {
            reject(status);
          }
        });
      });
      let directions = yield request;
      return directions;
    }),

    _registerWaypoint(waypoint) {
      Ember.run.schedule('actions', () => {
        Ember.get(this, 'waypoints').pushObject(waypoint);
      });
    },

    _unregisterWaypoint(waypoint) {
      Ember.run.schedule('actions', () => {
        Ember.get(this, 'waypoints').removeObject(waypoint);
      });
    }

  });

  _exports.default = _default;
});