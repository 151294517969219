define("ember-google-maps/templates/components/g-map/info-window", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7pHoR7W1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[32,0,[\"_targetPane\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,0,[\"_targetPane\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[35,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"content\",\"publicAPI\",\"if\",\"-in-el-null\",\"in-element\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/info-window.hbs"
    }
  });

  _exports.default = _default;
});