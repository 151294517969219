define("emberfire/session-stores/firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FirebaseSessionStore extends Ember.Object.extend(Ember.Evented, {
    firebaseApp: Ember.inject.service('firebase-app')
  }) {
    constructor() {
      super(...arguments);
      this.restoring = true;
      this.persist = Ember.RSVP.resolve;
      this.clear = Ember.RSVP.resolve;
    }

    restore() {
      return new Ember.RSVP.Promise(resolve => {
        Ember.get(this, 'firebaseApp').auth().then(auth => auth.onIdTokenChanged(user => Ember.run(() => {
          let authenticated = user ? {
            authenticator: 'authenticator:firebase',
            user,
            credential: user.getIdToken()
          } : {};

          if (Ember.get(this, 'restoring')) {
            Ember.set(this, 'restoring', false);
            resolve({
              authenticated
            });
          } else {
            this.trigger('sessionDataUpdated', {
              authenticated
            });
          }
        })));
      });
    }

  }

  _exports.default = FirebaseSessionStore;
});