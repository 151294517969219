define("ember-google-maps/templates/components/g-map/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9t47MMoM",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,3],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[34,4]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[32,0,[\"_shouldRenderDefaultCanvas\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[16,1,[34,0]],[16,0,[34,1]],[16,5,[34,2]],[17,1],[12],[18,2,null],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"id\",\"computedClasses\",\"style\",\"if\",\"_customCanvas\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/canvas.hbs"
    }
  });

  _exports.default = _default;
});