define("emberfire/authenticators/firebase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    resolve,
    reject
  } = Ember.RSVP;

  class FirebaseAuthenticator extends Ember.Object.extend(Ember.Evented, {
    firebaseApp: Ember.inject.service('firebase-app')
  }) {
    restore(data) {
      return resolve(data);
    }

    authenticate() {
      return reject(new Error('Please authenticate via the Firebase SDK directly.'));
    }

    invalidate() {
      return Ember.get(this, 'firebaseApp').auth().then(auth => auth.signOut());
    }

  }

  _exports.default = FirebaseAuthenticator;
});