define("ember-paper/components/paper-autocomplete/component", ["exports", "ember-paper/components/paper-autocomplete/template", "@ember-decorators/component", "ember-paper/utils/calculate-ac-position", "ember-paper/mixins/validation-mixin", "ember-power-select/utils/group-utils"], function (_exports, _template, _component, _calculateAcPosition, _validationMixin, _groupUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaperAutocomplete = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember.computed('onSearchTextChange', 'onSelectionChange'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class PaperAutocomplete extends Ember.Component.extend(_validationMixin.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isTouched", false);

      _defineProperty(this, "calculatePosition", _calculateAcPosition.default);
    }

    init() {
      this._initComponent();

      super.init(...arguments);
    } // Init autocomplete component


    _initComponent() {
      let {
        onSearchTextChange,
        onSelectionChange
      } = this;
      let hasTextChange = onSearchTextChange && typeof onSearchTextChange === 'function';
      let hasSelectionChange = onSelectionChange && typeof onSelectionChange === 'function';
      (false && !(hasTextChange || hasSelectionChange) && Ember.assert('<PaperAutocomplete> requires at least one of the `@onSelectionChange` or `@onSearchTextChange` functions to be provided.', hasTextChange || hasSelectionChange));
    }

    _onChange() {
      if (this.onSelectionChange) {
        this.onSelectionChange(...arguments);
      }
    }

    get validationProperty() {
      if (this.onSearchTextChange) {
        return 'searchText';
      } else {
        return 'selected';
      }
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.notifyValidityChange();
    }

    close() {
      this.didAnimateScale = false;
      this.set('isTouched', true);
      this.notifyValidityChange();

      if (this.onClose) {
        return this.onClose(...arguments);
      }
    }

    open(select, e) {
      if (e && e.type === 'mousedown') {
        return false;
      }

      this.didAnimateScale = false;
      this.notifyValidityChange();

      if (this.onOpen) {
        return this.onOpen(...arguments);
      }
    }

    focus(select, e) {
      if ((e.target.classList.contains('ember-paper-autocomplete-search-input') || e.target.classList.contains('md-input')) && !select.selected) {
        select.actions.open(e);
      }

      if (this.onFocus) {
        return this.onFocus(...arguments);
      }
    }

    blur() {
      this.notifyValidityChange();

      if (this.onBlur) {
        this.onBlur(...arguments);
      }
    }

    _onInput(term, select, e) {
      if (select.selected) {
        select.actions.select(null);
      }

      if (this.onSearchTextChange) {
        this.onSearchTextChange(term, select, e);
      } else {
        this.set('searchText', term);
      }

      if (!select.isOpen && e.type !== 'change') {
        select.actions.open(e);
      }

      this.notifyValidityChange();

      if (this.onInput) {
        this.onInput(...arguments);
      }

      return term;
    }

    _onCreate() {
      let text = this.publicAPI.searchText;

      if (this.onCreate) {
        this.onCreate(text, this.publicAPI);
      }

      this.publicAPI.actions.close();
    }

    scrollTo(option, select) {
      let optionsList = document.getElementById(`ember-power-select-options-${this.publicAPI.uniqueId}`);

      if (!optionsList) {
        return;
      }

      optionsList = optionsList.parentNode;
      let index = (0, _groupUtils.indexOfOption)(select.results, option);

      if (index === -1) {
        return;
      }

      let optionElement = optionsList.querySelector(`[data-option-index="${index}"]`);

      if (!optionElement) {
        return;
      }

      let optionTopScroll = optionElement.offsetTop;
      let optionBottomScroll = optionTopScroll + optionElement.offsetHeight;

      if (optionBottomScroll > optionsList.offsetHeight + optionsList.scrollTop) {
        optionsList.scrollTop = optionBottomScroll - optionsList.offsetHeight;
      } else if (optionTopScroll < optionsList.scrollTop) {
        optionsList.scrollTop = optionTopScroll;
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "_onChange", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "_onChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "validationProperty", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "validationProperty"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focus", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "focus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "blur", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "blur"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onInput", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_onInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_onCreate", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_onCreate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "scrollTo", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "scrollTo"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperAutocomplete;
  _exports.default = _default;
});