define("ember-svg-jar/inlined/previous", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20 0C8.95 0 0 8.954 0 20c0 11.05 8.954 20 20 20 11.05 0 20-8.954 20-20C40 8.95 31.046 0 20 0m5.056 9.981a1.812 1.812 0 010 2.588l-7.966 7.853 7.966 7.853a1.813 1.813 0 010 2.589 1.876 1.876 0 01-2.626 0l-9.279-9.147a1.813 1.813 0 010-2.589l9.279-9.147a1.862 1.862 0 011.313-.536c.475 0 .95.179 1.313.536\" fill=\"#000\" fill-rule=\"evenodd\" opacity=\".9\"/>",
    "attrs": {
      "height": "300",
      "width": "300",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 40 40"
    }
  };
  _exports.default = _default;
});