define("ember-basic-dropdown/components/basic-dropdown-trigger", ["exports", "@ember-decorators/component", "ember-basic-dropdown/templates/components/basic-dropdown-trigger"], function (_exports, _component, _basicDropdownTrigger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let BasicDropdownTrigger = (_dec = (0, _component.layout)(_basicDropdownTrigger.default), _dec2 = (0, _component.tagName)(''), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class BasicDropdownTrigger extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "eventType", 'click');

      _defineProperty(this, "stopPropagation", false);
    }

    // Actions
    handleMouseDown(e) {
      if (this.dropdown.disabled) {
        return;
      }

      if (this.eventType !== 'mousedown' || e.button !== 0) return;

      if (this.stopPropagation) {
        e.stopPropagation();
      }

      this._stopTextSelectionUntilMouseup();

      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }

      this.dropdown.actions.toggle(e);
    }

    handleClick(e) {
      if (typeof document === 'undefined') return;

      if (this.isDestroyed || !this.dropdown || this.dropdown.disabled) {
        return;
      }

      if (this.eventType !== 'click' || e.button !== 0) return;

      if (this.stopPropagation) {
        e.stopPropagation();
      }

      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }

      this.dropdown.actions.toggle(e);
    }

    handleKeyDown(e) {
      if (this.dropdown.disabled) {
        return;
      }

      if (e.keyCode === 13) {
        // Enter
        this.dropdown.actions.toggle(e);
      } else if (e.keyCode === 32) {
        // Space
        e.preventDefault(); // prevents the space to trigger a scroll page-next

        this.dropdown.actions.toggle(e);
      } else if (e.keyCode === 27) {
        this.dropdown.actions.close(e);
      }
    }

    handleTouchStart() {
      document.addEventListener('touchmove', this._touchMoveHandler);
    }

    handleTouchEnd(e) {
      this.toggleIsBeingHandledByTouchEvents = true;

      if (e && e.defaultPrevented || this.dropdown.disabled) {
        return;
      }

      if (!this.hasMoved) {
        this.dropdown.actions.toggle(e);
      }

      this.hasMoved = false;
      document.removeEventListener('touchmove', this._touchMoveHandler); // This next three lines are stolen from hammertime. This prevents the default
      // behaviour of the touchend, but synthetically trigger a focus and a (delayed) click
      // to simulate natural behaviour.

      e.target.focus();
      setTimeout(function () {
        if (!e.target) {
          return;
        }

        let event;

        try {
          event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window);
        } catch (e) {
          event = new Event('click');
        } finally {
          e.target.dispatchEvent(event);
        }
      }, 0);
      e.preventDefault();
    }

    removeGlobalHandlers() {
      if (typeof document === 'undefined') return;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      document.removeEventListener('mouseup', this._mouseupHandler, true);
    }

    _mouseupHandler() {
      document.removeEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.remove('ember-basic-dropdown-text-select-disabled');
    }

    _touchMoveHandler() {
      this.hasMoved = true;
      document.removeEventListener('touchmove', this._touchMoveHandler);
    } // Methods


    _stopTextSelectionUntilMouseup() {
      document.addEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.add('ember-basic-dropdown-text-select-disabled');
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "handleMouseDown", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleMouseDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleTouchStart", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTouchStart"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleTouchEnd", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTouchEnd"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "removeGlobalHandlers", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "removeGlobalHandlers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_mouseupHandler", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "_mouseupHandler"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "_touchMoveHandler", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "_touchMoveHandler"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = BasicDropdownTrigger;
});