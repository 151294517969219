define("ember-paper-link/components/paper-link-item-engine", ["exports", "ember-paper-link/components/paper-link-item"], function (_exports, _paperLinkItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperLinkItem.default.extend({
    linkComponentName: 'paper-link-engine'
  });

  _exports.default = _default;
});