define("ember-paper/components/paper-menu/content/component", ["exports", "ember-paper/components/paper-menu/content/template", "ember-css-transitions/mixins/transition-mixin", "@ember-decorators/component", "ember-paper/utils/key-constants"], function (_exports, _template, _transitionMixin, _component, _keyConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function waitForAnimations(element, callback) {
    let computedStyle = window.getComputedStyle(element);

    if (computedStyle.transitionDuration && computedStyle.transitionDuration !== '0s') {
      let eventCallback = function () {
        element.removeEventListener('transitionend', eventCallback);
        callback();
      };

      element.addEventListener('transitionend', eventCallback);
    } else if (computedStyle.animationName !== 'none' && computedStyle.animationPlayState === 'running') {
      let eventCallback = function () {
        element.removeEventListener('animationend', eventCallback);
        callback();
      };

      element.addEventListener('animationend', eventCallback);
    } else {
      callback();
    }
  }

  let PaperMenuContent = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('otherStyles', 'isActive'), _dec4 = Ember.computed('destination'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class PaperMenuContent extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "isActive", false);
    }

    get customStyles() {
      if (this.isActive) {
        return {};
      } else {
        return this.otherStyles;
      }
    }

    get destinationElement() {
      return document.getElementById(this.destination);
    }

    async animateIn() {
      await (0, _transitionMixin.nextTick)();
      this.set('isActive', true);
    }

    async animateOut(element) {
      let parentElement = this.renderInPlace ? element.parentElement.parentElement : element.parentElement; // workaround for https://github.com/miguelcobain/ember-paper/issues/1151. See also https://github.com/emberjs/ember.js/issues/18795.

      if (!parentElement) {
        parentElement = document.getElementById('ember-basic-dropdown-wormhole');
      }

      let clone = element.cloneNode(true);
      clone.id = `${clone.id}--clone`;
      parentElement.appendChild(clone);
      await (0, _transitionMixin.nextTick)();

      if (!this.isDestroyed) {
        this.set('isActive', false);
        clone.classList.add('md-leave');
        waitForAnimations(clone, function () {
          clone.classList.remove('md-active');
          parentElement.removeChild(clone);
        });
      } else {
        parentElement.removeChild(clone);
      }
    }

    focusItem(element) {
      let focusTarget = element.querySelector('.md-menu-focus-target'); // default to first non disabled item

      if (!focusTarget) {
        let menuItem = element.querySelector('md-menu-item:not([disabled])');
        focusTarget = menuItem && menuItem.firstElementChild;
      }

      if (focusTarget) {
        focusTarget.focus();
      }
    }

    handleKeyDown(ev) {
      switch (ev.which) {
        case _keyConstants.ESCAPE:
          this.dropdown.actions.close();
          break;

        case _keyConstants.LEFT_ARROW:
        case _keyConstants.UP_ARROW:
          ev.preventDefault();
          this.focusMenuItem(ev, -1);
          break;

        case _keyConstants.RIGHT_ARROW:
        case _keyConstants.DOWN_ARROW:
          ev.preventDefault();
          this.focusMenuItem(ev, 1);
          break;
      }
    }

    focusMenuItem(e, direction) {
      let focusTarget = e.target.closest('md-menu-item');

      do {
        if (direction > 0) {
          focusTarget = focusTarget.nextElementSibling;
        } else {
          focusTarget = focusTarget.previousElementSibling;
        }
      } while (focusTarget && !isFocusable(focusTarget));

      focusTarget = focusTarget && focusTarget.firstElementChild;

      if (focusTarget) {
        focusTarget.focus();
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "customStyles", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "customStyles"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "destinationElement", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "destinationElement"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateIn", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "animateIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "animateOut", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "animateOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focusItem", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "focusItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype)), _class2)) || _class) || _class);

  function isFocusable(el) {
    // is a menu-item, doesn't have tabindex -1 and is not disabled
    return el && el.tagName === 'MD-MENU-ITEM' && el.getAttribute('tabindex') !== -1 && el.getAttribute('disabled') === null;
  }

  var _default = PaperMenuContent;
  _exports.default = _default;
});