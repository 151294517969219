define("ember-paper/components/paper-autocomplete/highlight/component", ["exports", "ember-paper/components/paper-autocomplete/highlight/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /**
   * @class PaperAutocompleteHighlight
   * @extends Ember.Component
   */
  let PaperAutocompleteHighlight = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed('regex', 'label'), _dec4 = Ember.computed('searchText', 'flags'), _dec(_class = _dec2(_class = (_class2 = (_temp = class PaperAutocompleteHighlight extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "flags", '');
    }

    get tokens() {
      let string = `${this.label}`;
      let regex = this.regex;
      let tokens = [];
      let lastIndex = 0; // Use replace here, because it supports global and single regular expressions at same time.

      string.replace(regex, (match, index) => {
        let prev = string.slice(lastIndex, index);

        if (prev) {
          tokens.push({
            text: prev,
            isMatch: false
          });
        }

        tokens.push({
          text: match,
          isMatch: true
        });
        lastIndex = index + match.length;
      }); // Append the missing text as a token.

      let last = string.slice(lastIndex);

      if (last) {
        tokens.push({
          text: last,
          isMatch: false
        });
      }

      return tokens;
    }

    get regex() {
      let flags = this.flags;
      let text = this.searchText;
      return this.getRegExp(text, flags);
    }

    getRegExp(term, flags) {
      let startFlag = '';
      let endFlag = '';
      let regexTerm = this.sanitizeRegex(term);

      if (flags.indexOf('^') >= 0) {
        startFlag = '^';
      }

      if (flags.indexOf('$') >= 0) {
        endFlag = '$';
      }

      return new RegExp(startFlag + regexTerm + endFlag, flags.replace(/[$^]/g, ''));
    }

    sanitizeRegex(term) {
      return term && term.toString().replace(/[\\^$*+?.()|{}[\]]/g, '\\$&');
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "tokens", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "tokens"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "regex", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "regex"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperAutocompleteHighlight;
  _exports.default = _default;
});