define("ember-paper-link/templates/components/paper-link-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+6nGYxzB",
    "block": "{\"symbols\":[\"controls\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],null,[[\"checkbox\",\"button\",\"switch\",\"radio\"],[[30,[36,3],[\"paper-checkbox\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[32,0],false,true]]],[30,[36,3],[\"paper-button\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\",\"skipProxy\"],[[32,0],false,true,true]]],[30,[36,3],[\"paper-switch\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[32,0],false,true]]],[30,[36,3],[\"paper-radio-proxiable\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[32,0],false,true]]]]]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[35,2]],[[\"params\",\"class\",\"replace\"],[[35,1],\"md-no-style\",[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"md-list-item-inner\"],[12],[2,\"\\n      \"],[18,2,[[32,1]]],[2,\"\\n      \"],[8,\"paper-ripple\",[],[[\"@noink\",\"@dimBackground\"],[[32,0,[\"noink\"]],true]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"replace\",\"params\",\"linkComponentName\",\"component\",\"hash\",\"with\"]}",
    "meta": {
      "moduleName": "ember-paper-link/templates/components/paper-link-item.hbs"
    }
  });

  _exports.default = _default;
});