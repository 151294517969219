define("emberfire/mixins/analytics-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    firebaseApp: Ember.inject.service('firebase-app'),
    router: Ember.inject.service('router'),

    init() {
      this._super(...arguments);

      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const router = Ember.get(this, 'router');
      router.on('routeDidChange', () => {
        const firebase = Ember.get(this, 'firebaseApp');
        const app_name = config.APP.name || 'Ember App';
        const app_version = config.APP.version || undefined;
        const screen_name = router.currentRouteName;
        const url = router.currentURL;
        firebase.analytics().then(analytics => {
          analytics.setCurrentScreen(screen_name || url, {
            global: true
          });
          analytics.logEvent("screen_view", {
            app_name,
            screen_name,
            url,
            app_version
          });
        });
      });
    }

  });

  _exports.default = _default;
});