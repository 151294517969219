define("ember-paper-link/components/paper-link", ["exports", "ember-paper-link/templates/components/paper-link", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/proxiable-mixin"], function (_exports, _paperLink, _focusableMixin, _colorMixin, _proxiableMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend(_focusableMixin.default, _colorMixin.default, _proxiableMixin.default, {
    layout: _paperLink.default,
    // Add needed paper-button properties
    classNames: ['md-default-theme', 'md-button', 'paper-link'],
    raised: false,
    iconButton: false,
    fab: Ember.computed.reads('mini'),
    // circular button
    mini: false,
    // For raised buttons: toggle disabled when button is active
    disabled: Ember.computed.and('raised', 'active'),
    classNameBindings: ['raised:md-raised', 'iconButton:md-icon-button', 'fab:md-fab', 'mini:md-mini'],
    // FocusableMixin overrides active so set back to link-to's active
    active: Ember.LinkComponent.active,

    // FocusableMixin overrides to prevent changing 'active' property
    down() {
      this.set('pressed', true);
    },

    up() {
      this.set('pressed', false);
    }

  });

  _exports.default = _default;
});