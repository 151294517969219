define("ember-google-maps/components/g-map/autocomplete", ["exports", "ember-google-maps/components/g-map/map-component", "ember-google-maps/templates/components/g-map/autocomplete", "ember-google-maps/utils/options-and-events"], function (_exports, _mapComponent, _autocomplete, _optionsAndEvents) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AutocompleteAPI = AutocompleteAPI;
  _exports.default = void 0;

  function AutocompleteAPI(source) {
    let mapComponentAPI = (0, _mapComponent.MapComponentAPI)(source);
    return (0, _mapComponent.combine)(mapComponentAPI, {
      get place() {
        return source.place;
      }

    });
  }
  /**
   * @class Autocomplete
   * @namespace GMap
   * @module ember-google-maps/components/g-map/autocomplete
   * @extends GMap.MapComponent
   */


  var _default = _mapComponent.default.extend({
    layout: _autocomplete.default,
    tagName: 'div',
    _type: 'autocomplete',
    _optionsAndEvents: (0, _optionsAndEvents.parseOptionsAndEvents)([..._optionsAndEvents.ignoredOptions, 'onSearch']),

    init() {
      this._super(...arguments);

      this.publicAPI = AutocompleteAPI(this);
    },

    _addComponent(options) {
      let map = Ember.get(this, 'map');
      let inputElement = this.element.querySelector('input');
      (false && !(inputElement) && Ember.assert('You must define your own input within the ember-google-maps autocomplete block.', inputElement));
      let autocomplete = new google.maps.places.Autocomplete(inputElement, options);
      Ember.set(this, 'mapComponent', autocomplete); // Bias the search results to the current map bounds.

      autocomplete.setBounds(map.getBounds());
      map.addListener('bounds_changed', function () {
        autocomplete.setBounds(map.getBounds());
      });
      autocomplete.addListener('place_changed', this._onSearch.bind(this));
      return Ember.RSVP.resolve(this.mapComponent);
    },

    _onSearch() {
      this.place = this.mapComponent.getPlace();

      if (this.place.geometry) {
        Ember.tryInvoke(this, 'onSearch', [this.publicAPI]);
      }
    }

  });

  _exports.default = _default;
});