define("ember-paper/components/paper-chips/component", ["exports", "ember-paper/components/paper-chips/template", "@ember-decorators/component"], function (_exports, _template, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _class2, _temp;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let PaperChips = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class PaperChips extends Ember.Component {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "activeChip", -1);

      _defineProperty(this, "searchText", '');

      _defineProperty(this, "isFocused", false);
    }

    handleFocusIn() {
      this.set('isFocused', true);
    }

    handleFocusOut() {
      this.set('isFocused', false);
      this.set('activeChip', -1);
    }

    handleClick(ev) {
      ev.currentTarget.querySelector('.md-chip-input-container input').focus();
    }

    handleKeydown(ev) {
      let input = ev.currentTarget.querySelector('.md-chip-input-container input');

      if (!this.readOnly && Ember.isEmpty(input.value) && this.content.length) {
        this.keyboardNavigation(ev, input);
      }
    }

    keyboardNavigation(ev, input) {
      // No text has been entered, but we have chips; cursor keys should select chips.
      let current = this.activeChip;
      let chips = this.content;
      let key = ev.key;

      if (['ArrowLeft', 'Left'].includes(key) || key === 'Backspace' && current === -1) {
        if (current === -1) {
          input.blur();
          ev.currentTarget.focus();
          this.set('activeChip', chips.length - 1);
        } else if (current > 0) {
          this.set('activeChip', this.activeChip - 1);
        }
      } else if (['ArrowRight', 'Right'].includes(key)) {
        if (current >= 0) {
          this.set('activeChip', this.activeChip + 1);
        }

        if (this.get('activeChip') >= chips.length) {
          this.set('activeChip', -1);
          input.focus();
        }
      } else if (current >= 0 && ['Backspace', 'Delete', 'Del'].includes(key)) {
        this.removeItem(chips[current]);
        this.set('activeChip', Math.min(chips.length - 1, this.activeChip));
      }
    }

    handleAutocompleteChange(item, select) {
      if (item && select.isOpen) {
        // Trigger onChange for the new item.
        if (this.addItem) {
          this.addItem(item);
        }

        select.actions.search('');
        this.set('searchText', '');
      }
    }

    handleSearchTextChange(value, select) {
      this.set('searchText', value); // Close dropdown if search text is cleared by the user.

      if (Ember.isEmpty(value)) {
        select.actions.close();
      }
    }

    handleAutocompleteOpen(select, e) {
      if (e && e.type === 'focus') {
        return false;
      }
    }

    handleAddItem(newItem, select) {
      if (this.requireMatch) {
        // Don't add a new item - we're set to require a match.
        return;
      }

      if (Ember.isPresent(newItem)) {
        let item = newItem;

        if (Ember.isPresent(this.searchField)) {
          item = {};
          item[this.searchField] = newItem;
        }

        if (this.addItem) {
          this.addItem(item);
        }

        if (select) {
          select.actions.search('');
        }

        this.set('searchText', '');
      }
    }

    handleRemoveItem(item) {
      if (this.removeItem) {
        this.removeItem(item);
      }

      let current = this.activeChip;

      if (current === -1 || current >= this.content.length) {
        this.set('activeChip', -1);
      }
    }

    handleInputKeydown(ev) {
      if (ev.key === 'Enter') {
        this.handleAddItem(ev.target.value);
        ev.target.value = '';
      }
    }

    handleChipClick(index, ev) {
      ev.stopPropagation();

      if (!this.readOnly) {
        this.set('activeChip', index);
      }
    }

  }, _temp), (_applyDecoratedDescriptor(_class2.prototype, "handleFocusIn", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocusOut", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusOut"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleClick", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "handleClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeydown", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleAutocompleteChange", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAutocompleteChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSearchTextChange", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearchTextChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleAutocompleteOpen", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAutocompleteOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleAddItem", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "handleAddItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleRemoveItem", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "handleRemoveItem"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleInputKeydown", [_dec12], Object.getOwnPropertyDescriptor(_class2.prototype, "handleInputKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleChipClick", [_dec13], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChipClick"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperChips;
  _exports.default = _default;
});