define("ember-file-upload/components/file-dropzone/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xKl+H/bR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,4],null,[[\"supported\",\"active\",\"valid\"],[[35,3],[35,2],[35,1]]]],[35,0]]]],\"hasEval\":false,\"upvars\":[\"queue\",\"valid\",\"active\",\"supported\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-file-upload/components/file-dropzone/template.hbs"
    }
  });

  _exports.default = _default;
});