define("ember-composable-helpers/helpers/drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.drop = drop;
  _exports.default = void 0;

  function drop([dropAmount, array]) {
    if (!array) {
      array = [];
    }

    return array.slice(dropAmount);
  }

  var _default = Ember.Helper.helper(drop);

  _exports.default = _default;
});