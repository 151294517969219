define("ember-composable-helpers/helpers/take", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.take = take;
  _exports.default = void 0;

  function take([takeAmount, array]) {
    if (!array) {
      array = [];
    }

    return array.slice(0, takeAmount);
  }

  var _default = Ember.Helper.helper(take);

  _exports.default = _default;
});