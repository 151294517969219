define("ember-google-maps/templates/components/-private-api/addon-factory", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9TTs6g3A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[2,\"\\n\"],[18,1,[[30,[36,4],null,[[\"marker\",\"circle\",\"polyline\",\"infoWindow\",\"overlay\",\"control\",\"autocomplete\",\"directions\",\"route\"],[[30,[36,3],[\"g-map/marker\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"marker\"]]],[30,[36,3],[\"g-map/circle\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"circle\"]]],[30,[36,3],[\"g-map/polyline\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"polyline\"]]],[30,[36,3],[\"g-map/info-window\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"infoWindow\"]]],[30,[36,3],[\"g-map/overlay\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"overlay\"]]],[30,[36,3],[\"g-map/control\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"control\"]]],[30,[36,3],[\"g-map/autocomplete\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"autocomplete\"]]],[30,[36,3],[\"g-map/directions\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"directions\"]]],[30,[36,3],[\"g-map/route\"],[[\"map\",\"_internalAPI\",\"gMap\",\"_name\"],[[35,2],[35,1],[35,0],\"route\"]]]]]]]],[2,\"\\n    \"]],\"hasEval\":false,\"upvars\":[\"gMap\",\"_internalAPI\",\"map\",\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/-private-api/addon-factory.hbs"
    }
  });

  _exports.default = _default;
});