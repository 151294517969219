define("ember-aria-tabs/templates/components/aria-tab-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kDrDLpzy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"shouldYield\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,[[30,[36,0],null,[[\"selected\"],[[32,0,[\"selected\"]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"if\"]}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tab-panel.hbs"
    }
  });

  _exports.default = _default;
});