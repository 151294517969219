define("ember-svg-jar/inlined/arrow_l", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M53.434 21c-15.738 0-28.5 12.76-28.5 28.5S37.695 78 53.434 78c15.74 0 28.5-12.76 28.5-28.5S69.174 21 53.434 21zm5.41 36.104a1.5 1.5 0 11-2.118 2.126l-9.75-9.71 9.73-10.063a1.5 1.5 0 012.156 2.085l-7.676 7.937 7.658 7.625z\"/>",
    "attrs": {
      "height": "300",
      "width": "300",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});