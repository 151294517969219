define("emberfire/services/firebase", ["exports", "firebase/app"], function (_exports, _app) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore Node.js issues
  const firebase = _app.default;

  class FirebaseService extends Ember.Service {
    constructor() {
      super(...arguments);
      this.root = firebase;

      this.app = name => firebase.app(name);

      this.apps = firebase.apps;

      this.initializeApp = (options, nameOrConfig) => firebase.initializeApp(options, nameOrConfig);
    }

  }

  _exports.default = FirebaseService;
});