define("ember-svg-jar/inlined/marker1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><path d=\"M78 33C78 17.5 65.5 5 50 5S22 17.5 22 33v.4c0 1.1.1 2.4.3 3.8v.2C25.2 55.9 45.1 92 45.1 92c.9 1.8 2.8 3 4.9 3s4-1.2 4.9-3c0 0 19.9-36.1 22.7-54.7v-.2c.2-1.4.3-2.6.3-3.8.1 0 .1-.2.1-.3zM50 48.5c-8.3 0-15-6.7-15-15s6.7-15 15-15 15 6.7 15 15-6.7 15-15 15z\"/>",
    "attrs": {
      "class": "gmapsmarker1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
  _exports.default = _default;
});