define("ember-composable-helpers/helpers/reduce", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reduce = reduce;
  _exports.default = void 0;

  function reduce([callback, initialValue, array]) {
    if (Ember.isEmpty(callback)) {
      return [];
    }

    return array.reduce(callback, initialValue);
  }

  var _default = Ember.Helper.helper(reduce);

  _exports.default = _default;
});