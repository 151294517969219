define("ember-google-maps/templates/components/g-map/overlay", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "F25MMtvs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,0,[\"_contentContainer\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,0,[\"_contentContainer\"]]],null]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",[29]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-in-el-null\",\"in-element\",\"if\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/overlay.hbs"
    }
  });

  _exports.default = _default;
});