define("ember-paper/components/paper-select/component", ["exports", "ember-paper/components/paper-select/template", "@ember-decorators/component", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin", "ember-paper/utils/clamp"], function (_exports, _template, _component, _childMixin, _validationMixin, _clamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _class2, _descriptor, _descriptor2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const SELECT_EDGE_MARGIN = 8;

  function getOffsetRect(node) {
    return node ? {
      left: node.offsetLeft,
      top: node.offsetTop,
      width: node.offsetWidth,
      height: node.offsetHeight
    } : {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    };
  }

  let PaperSelect = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec3 = Ember.computed.and('isInvalid', 'isTouched'), _dec4 = Ember.computed.and('isFocused', 'selected'), _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec(_class = _dec2(_class = (_class2 = (_temp = class PaperSelect extends Ember.Component.extend(_validationMixin.default, _childMixin.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "validationProperty", 'selected');

      _defineProperty(this, "isTouched", false);

      _defineProperty(this, "isFocused", false);

      _initializerDefineProperty(this, "isInvalidAndTouched", _descriptor, this);

      _initializerDefineProperty(this, "isFocusedAndSelected", _descriptor2, this);
    }

    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.notifyValidityChange();
    }

    close() {
      this.didAnimateScale = false;
      this.set('isTouched', true);
      this.notifyValidityChange();
    }

    open() {
      this.didAnimateScale = false;
      this.notifyValidityChange();
    }

    focus() {
      this.set('isFocused', true);
    }

    blur() {
      this.set('isFocused', false);
    }

    calculatePosition(trigger, content) {
      let opts = {
        target: trigger,
        parent: document.body,
        selectEl: content.querySelector('md-select-menu'),
        contentEl: content.querySelector('md-content')
      };
      let containerNode = content;
      let targetNode = opts.target.firstElementChild; // target the label

      let parentNode = opts.parent;
      let selectNode = opts.selectEl;
      let contentNode = opts.contentEl;
      let parentRect = parentNode.getBoundingClientRect();
      let targetRect = targetNode.getBoundingClientRect();
      let shouldOpenAroundTarget = false;
      let bounds = {
        left: parentRect.left + SELECT_EDGE_MARGIN,
        top: SELECT_EDGE_MARGIN,
        bottom: parentRect.height - SELECT_EDGE_MARGIN,
        right: parentRect.width - SELECT_EDGE_MARGIN
      };
      let spaceAvailable = {
        top: targetRect.top - bounds.top,
        left: targetRect.left - bounds.left,
        right: bounds.right - (targetRect.left + targetRect.width),
        bottom: bounds.bottom - (targetRect.top + targetRect.height)
      };
      let maxWidth = parentRect.width - SELECT_EDGE_MARGIN * 2;
      let selectedNode = selectNode.querySelector('md-option[selected]');
      let optionNodes = selectNode.getElementsByTagName('md-option');
      let optgroupNodes = selectNode.getElementsByTagName('md-optgroup');
      let centeredNode, left, top, transformOrigin; // If a selected node, center around that

      if (selectedNode) {
        centeredNode = selectedNode; // If there are option groups, center around the first option group
      } else if (optgroupNodes.length) {
        centeredNode = optgroupNodes[0]; // Otherwise, center around the first optionNode
      } else if (optionNodes.length) {
        centeredNode = optionNodes[0]; // In case there are no options, center on whatever's in there... (eg progress indicator)
      } else {
        centeredNode = contentNode.firstElementChild || contentNode;
      }

      if (contentNode.offsetWidth > maxWidth) {
        contentNode.style['max-width'] = `${maxWidth}px`;
      }

      if (shouldOpenAroundTarget) {
        contentNode.style['min-width'] = `${targetRect.width}px`;
      } // Remove padding before we compute the position of the menu


      let focusedNode = centeredNode;

      if ((focusedNode.tagName || '').toUpperCase() === 'MD-OPTGROUP') {
        focusedNode = optionNodes[0] || contentNode.firstElementChild || contentNode;
        centeredNode = focusedNode;
      } // Get the selectMenuRect *after* max-width is possibly set above


      containerNode.style.display = 'block';
      let selectMenuRect = selectNode.getBoundingClientRect();
      let centeredRect = getOffsetRect(centeredNode);

      if (centeredNode) {
        let centeredStyle = window.getComputedStyle(centeredNode);
        centeredRect.paddingLeft = parseInt(centeredStyle.paddingLeft, 10) || 0;
        centeredRect.paddingRight = parseInt(centeredStyle.paddingRight, 10) || 0;
      } // Get scrollHeight/offsetHeight *after* container is set with display:block


      let isScrollable = contentNode.scrollHeight > contentNode.offsetHeight;

      if (isScrollable) {
        let scrollBuffer = contentNode.offsetHeight / 2;
        contentNode.scrollTop = centeredRect.top + centeredRect.height / 2 - scrollBuffer;

        if (spaceAvailable.top < scrollBuffer) {
          contentNode.scrollTop = Math.min(centeredRect.top, contentNode.scrollTop + scrollBuffer - spaceAvailable.top);
        } else if (spaceAvailable.bottom < scrollBuffer) {
          contentNode.scrollTop = Math.max(centeredRect.top + centeredRect.height - selectMenuRect.height, contentNode.scrollTop - scrollBuffer + spaceAvailable.bottom);
        }
      }

      if (shouldOpenAroundTarget) {
        left = targetRect.left;
        top = targetRect.top + targetRect.height;
        transformOrigin = '50% 0';

        if (top + selectMenuRect.height > bounds.bottom) {
          top = targetRect.top - selectMenuRect.height;
          transformOrigin = '50% 100%';
        }
      } else {
        left = targetRect.left + centeredRect.left - centeredRect.paddingLeft + 2;
        top = Math.floor(targetRect.top + targetRect.height / 2 - centeredRect.height / 2 - centeredRect.top + contentNode.scrollTop) + 2;
        transformOrigin = `${centeredRect.left + targetRect.width / 2}px
        ${centeredRect.top + centeredRect.height / 2 - contentNode.scrollTop}px 0px`;
        containerNode.style.minWidth = `${targetRect.width + centeredRect.paddingLeft + centeredRect.paddingRight}px`;
      }

      let containerRect = containerNode.getBoundingClientRect();
      let dropdownTop = (0, _clamp.default)(bounds.top, top, bounds.bottom - containerRect.height);
      let dropdownLeft = (0, _clamp.default)(bounds.left, left, bounds.right - containerRect.width);
      let scaleX = Math.min(targetRect.width / selectMenuRect.width, 1.0);
      let scaleY = Math.min(targetRect.height / selectMenuRect.height, 1.0);
      let style = {
        top: dropdownTop,
        left: dropdownLeft,
        // Animate a scale out if we aren't just repositioning
        transform: !this.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        'transform-origin': transformOrigin
      };
      this.didAnimateScale = true;
      return {
        style,
        horizontalPosition: '',
        verticalPosition: ''
      };
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isInvalidAndTouched", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isFocusedAndSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "close", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focus", [_dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "focus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "blur", [_dec8], Object.getOwnPropertyDescriptor(_class2.prototype, "blur"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "calculatePosition", [_dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "calculatePosition"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = PaperSelect;
  _exports.default = _default;
});