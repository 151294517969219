define("ember-svg-jar/inlined/marker-narrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3567 11895c-88-19-185-63-262-120-99-72-162-152-240-300-86-162-141-266-173-325-88-164-109-204-180-340-171-328-221-425-441-865-206-413-441-893-441-901 0-1-35-76-79-166-43-90-138-293-210-453-72-159-142-312-155-340-35-76-81-181-121-275-20-47-45-103-55-125s-36-85-59-140-53-127-67-160c-59-136-83-194-112-265-28-71-57-143-123-305-15-38-44-113-64-165s-42-111-50-130c-30-75-83-217-98-260-9-25-32-90-52-145-45-126-222-665-264-805-112-375-193-736-233-1040-20-153-23-215-23-500 1-301 3-338 28-503 40-260 106-524 189-747 22-58 48-130 60-160 19-53 143-307 185-380 54-94 165-268 230-360 152-216 436-513 665-698 71-56 131-102 135-102s16-8 27-19c59-53 275-189 418-261 57-29 110-57 118-61 250-127 689-272 942-310 35-5 126-19 203-31 122-19 182-21 465-21s343 2 465 21c77 12 168 26 203 31 253 38 692 183 942 310 8 4 61 32 118 61 143 72 359 208 418 261 11 11 23 19 27 19s64 46 135 102c229 185 513 482 665 698 162 231 354 572 415 740 12 30 38 102 60 160 118 317 204 729 219 1050 6 129-6 353-33 615-38 377-167 928-342 1455-13 41-30 95-37 120s-43 131-80 235c-104 295-113 323-203 560-41 108-129 333-146 370-7 17-35 84-60 150-26 66-54 136-63 155s-24 55-34 80c-22 56-75 185-93 225-8 17-33 75-56 130s-50 118-60 140-32 72-48 110c-28 64-139 313-199 445-14 30-33 73-43 95-24 53-96 207-125 270-13 28-44 93-69 145-129 274-166 353-264 555-177 366-598 1199-722 1430-21 39-66 124-101 190s-80 152-101 190c-21 39-50 95-66 125-103 197-148 269-211 333-41 43-161 137-175 137-3 0-29 11-58 25-110 52-307 70-441 40zm459-6096c72-10 169-30 215-43s98-27 114-31c41-9 150-56 275-118 419-209 779-606 946-1047 195-512 173-1073-62-1575-64-136-172-299-290-437-205-237-475-429-769-547-373-150-858-175-1260-64-154 42-209 64-377 148-288 145-548 375-730 646-360 535-436 1220-204 1829 167 441 527 838 946 1047 125 62 234 109 275 118 17 4 68 18 115 32 241 68 527 83 806 42z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "746",
      "height": "1200",
      "viewBox": "0 0 7460 12000"
    }
  };
  _exports.default = _default;
});