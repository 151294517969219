define("ember-google-maps/templates/components/g-map/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0oZQtyEj",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,1],null,[[\"directions\"],[[35,0]]]]]]],\"hasEval\":false,\"upvars\":[\"directions\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-google-maps/templates/components/g-map/route.hbs"
    }
  });

  _exports.default = _default;
});