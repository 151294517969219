define("ember-paper-link/components/paper-link-item", ["exports", "ember-paper/components/paper-item", "ember-paper-link/templates/components/paper-link-item"], function (_exports, _paperItem, _paperLinkItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperItem.default.extend({
    layout: _paperLinkItem.default,
    hasPrimaryAction: true,
    replace: false,
    linkComponentName: 'paper-link'
  }).reopenClass({
    positionalParams: 'params'
  });

  _exports.default = _default;
});