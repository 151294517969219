define("emberfire/initializers/emberfire", ["exports", "firebase/app", "emberfire/services/firebase-app", "emberfire/services/realtime-listener", "emberfire/adapters/firestore", "emberfire/serializers/firestore", "emberfire/adapters/realtime-database", "emberfire/serializers/realtime-database"], function (_exports, _app, _firebaseApp, _realtimeListener, _firestore, _firestore2, _realtimeDatabase, _realtimeDatabase2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // @ts-ignore export for Node problem
  const firebase = _app.default;

  const initialize = application => {
    const environment = application.resolveRegistration('config:environment');

    if (!environment || typeof environment.firebase !== 'object') {
      throw new Error('Please set the `firebase` property in your environment config.');
    }

    if (typeof environment.firebase.length === 'undefined') {
      loadEnvironment(application, environment.firebase);
    } else {
      environment.firebase.forEach(config => loadEnvironment(application, config));
    }

    application.register("service:realtime-listener", _realtimeListener.default.extend({}), {
      instantiate: true
    });
    application.register('adapter:-firestore', _firestore.default);
    application.register('serializer:-firestore', _firestore2.default);
    application.register('adapter:-realtime-database', _realtimeDatabase.default);
    application.register('serializer:-realtime-database', _realtimeDatabase2.default);
  };

  const loadEnvironment = (application, environment) => {
    const config = Object.assign({}, environment);
    delete config.options;
    delete config.name;
    const options = Object.assign({}, config.options);
    options.name = options.name || environment.name;
    firebase.initializeApp(config, options);
    const serviceName = options.name === '[DEFAULT]' && `firebase-app` || `firebase-${options.name}`;
    application.register(`service:${serviceName}`, _firebaseApp.default.extend({
      name: options.name
    }), {
      instantiate: true
    });
  };

  var _default = {
    name: 'emberfire',
    initialize: initialize
  };
  _exports.default = _default;
});