define("ember-aria-tabs/templates/components/aria-tab-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "heC9LZME",
    "block": "{\"symbols\":[\"@tabNodes\",\"@selectedIndex\",\"@selectedTabClassName\",\"@parentGuid\",\"@panelNodes\",\"@onKeyDown\",\"@onClick\",\"@focus\",\"@disabledTabClassName\",\"&default\"],\"statements\":[[18,10,[[30,[36,1],null,[[\"tab\"],[[30,[36,0],[\"aria-tab\"],[[\"disabledTabClassName\",\"focus\",\"onClick\",\"onKeyDown\",\"panelNodes\",\"parentGuid\",\"selectedTabClassName\",\"selectedIndex\",\"tabNodes\"],[[32,9],[32,8],[32,7],[32,6],[32,5],[32,4],[32,3],[32,2],[32,1]]]]]]]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "meta": {
      "moduleName": "ember-aria-tabs/templates/components/aria-tab-list.hbs"
    }
  });

  _exports.default = _default;
});